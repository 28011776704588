// @ts-strict-ignore
import React, { type FC } from 'react';
import { Grid } from 'bb/common/grid';
import { Image } from 'bb/common/images';
import { SM_100VW_LG_50VW } from 'bb/common/images/config';
import { type ImageData } from 'bb/common/images/types';
import { LinkButton } from 'bb/common/LinkButton';
import { Section } from 'bb/common/section';
import css from 'bb/discovery/start/start.module.scss';
import { Text } from 'bb/i18n';
import { Box, Row, Stack } from 'bb/ui';
import promoCss from './studentPromo.module.scss';

type StudentPromoProps = {
    image: ImageData;
};

export const StudentPromo: FC<StudentPromoProps> = ({ image }) => {
    return (
        <Section>
            <Grid>
                <Box className={promoCss.backgroundImage}>
                    <Box
                        padding="small"
                        bgColor="primary-white"
                        className={promoCss.box}
                    >
                        <Stack justifyContent="center">
                            <Text as="h2" t="student:studentPromoHeader" center>
                                <Text variant="h3Secondary" as="span" />
                                <Text center variant="h3Primary" as="span" />
                            </Text>

                            <Row marginTop="medium" justifyContent="center">
                                <LinkButton
                                    as="a"
                                    variant="contained"
                                    route="student"
                                >
                                    <Text t="student:studentPromoReadMore" />
                                </LinkButton>
                            </Row>
                        </Stack>
                    </Box>
                </Box>
                <Image
                    data-optimizely="student-promo-image"
                    className={css.subHeroImage}
                    img={image}
                    config={SM_100VW_LG_50VW}
                />
            </Grid>
        </Section>
    );
};
